import _uniqBy from "lodash/uniqBy"
import { memoizedFn, memoTimeout } from "../Cache"
import {
  PrismicDal,
  prismicDal as globalPrismicDal
} from "../prismic/PrismicDal"
import { DomainCategory } from "../product/models/DomainCategory"
import { Country } from "@sixty-six-north/i18n"

class ShelvesDal {
  private prismicDal: PrismicDal
  constructor(prismicDal: PrismicDal) {
    this.prismicDal = prismicDal
  }

  public shelvesFor(
    country: Country,
    category: DomainCategory | undefined = undefined
  ): Promise<Shelf[]> {
    const fetchShelves = () => {
      return this.prismicDal
        .shelvesFor(country)
        .then(
          it =>
            it.find(d => d.data?.category?.key === category?.key) ||
            it.find(d => d.data?.category === null)
        )
        .then(it => it?.data?.shelves || [])
        .then(it =>
          it.map(s => ({
            id: s.shelf_id,
            name: s.shelf_name[0]?.text || "",
            description: s.shelf_description[0]?.text || ""
          }))
        )
        .then(it => _uniqBy(it, "id"))
    }

    if (category) {
      return memoizedFn(
        `shelves-for-${country}-${category.key}`,
        memoTimeout.minutes(5),
        () => fetchShelves()
      )
    } else {
      return fetchShelves()
    }
  }
}

export interface Shelf {
  id: string
  name: string
  description: string
}

export const shelvesDal = new ShelvesDal(globalPrismicDal)
