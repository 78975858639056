import { Country, stringToCountry } from "@sixty-six-north/i18n"
import fetch from "isomorphic-unfetch"
import _isArray from "lodash/isArray"
import { ParsedUrlQuery } from "querystring"
import { AppConfig } from "../Config"
import { createLogger } from "../utils/createLogger"
import { pathToCountry } from "./Country"
import { headerXClientCountryValueToCountry } from "./HeadeXClientCountryValueToCountry"
import { DEFAULT_REGION, Region, REGION_COUNTRIES } from "./Region"

const logger = createLogger("CountryResolver")

export interface CountryArgs {
  ipAddress?: string
  query?: ParsedUrlQuery
  locale?: string
  httpHeaderXClientCountry?: string
  regionInPath?: Country
  cookies?: Record<string, string>
  prioritiseRegionFromCookie?: boolean
}

export interface ProposedCountry {
  country: Country
  userSelected: boolean
}

export enum CountryResolverErrors {
  CountryDoesNotExist = "CountryDoesNotExist"
}

const propose = (country: Country): ProposedCountry => ({
  country,
  userSelected: false
})

const previouslySelected = (country: Country): ProposedCountry => ({
  country,
  userSelected: true
})

export class CountryResolver {
  private fetchCountry: (ipAddress: string) => Promise<Country>

  constructor(fetchCountry: (ipAddress: string) => Promise<Country>) {
    this.fetchCountry = fetchCountry
  }

  public resolve({
    ipAddress,
    query,
    httpHeaderXClientCountry,
    cookies
  }: CountryArgs): Promise<ProposedCountry> {
    if (query?.region) {
      return this.getCountryFromPath(query.region).then(previouslySelected)
    } else if (cookies?.country) {
      return this.getCountryFromCookie(cookies.country).then(previouslySelected)
    } else if (httpHeaderXClientCountry) {
      const result = headerXClientCountryValueToCountry(
        httpHeaderXClientCountry
      )
      return Promise.resolve(result).then(propose)
    } else if (ipAddress) {
      return this.getCountryFromIpAddress(ipAddress).then(propose)
    } else {
      return Promise.reject(CountryResolverErrors.CountryDoesNotExist)
    }
  }

  private getCountryFromPath(path: string | string[]): Promise<Country> {
    const resolve = () => {
      if (_isArray(path)) return pathToCountry(path[0])
      else return pathToCountry(path)
    }

    const result = resolve()

    if (result) return Promise.resolve(result)
    else return Promise.reject(CountryResolverErrors.CountryDoesNotExist)
  }

  private getCountryFromCookie(cookieValue: string): Promise<Country> {
    const result = stringToCountry(cookieValue)
    if (result) return Promise.resolve(result)
    else return Promise.reject(CountryResolverErrors.CountryDoesNotExist)
  }

  private getCountryFromIpAddress(ipAddress: string): Promise<Country> {
    return this.fetchCountry(ipAddress)
  }
}

export const geoLocationEndpoint = (ipAddress: string | undefined) => {
  const host = AppConfig.isDevMode
    ? "http://localhost:3000"
    : "https://www.66north.com"
  return `${host}/api/geolocation?ipAddress=${ipAddress}`
}

export const resolveCountryFromIpAddress = async (
  ipAddress: string | undefined
): Promise<Country> => {
  if (!ipAddress) {
    return Promise.resolve(DEFAULT_REGION.country)
  } else {
    return fetch(geoLocationEndpoint(ipAddress))
      .then(it => it.json())
      .then(it => it as Region)
      .then(it => it.country)
      .catch(err => {
        logger.error(
          `Error resolving region from IP address: ipAddress=${ipAddress}`,
          err
        )
        return Promise.resolve(DEFAULT_REGION.country)
      })
  }
}
