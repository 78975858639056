import { stringToCountry } from "@sixty-six-north/i18n"
import { GetServerSidePropsContext } from "next"
import { parseCookies } from "nookies"
import { countryToStore } from "../cart/I18NStores"
import { Store } from "../cart/Stores"
import { CURRENT_STORE_COOKIE_NAME } from "../i18n/Cookies"

export interface Cookies {
  cookies: Record<string, string>
}

export class CookieStoreResolver {
  public static async resolveStoreFromUserPreference(
    context: GetServerSidePropsContext
  ) {
    const cookies = parseCookies(context)
    return CookieStoreResolver.resolveStoreFromCookies({ cookies })
  }

  public static async resolveStoreFromCurrentStoreCookie(
    context: GetServerSidePropsContext
  ): Promise<Store | undefined> {
    const cookies = parseCookies(context)
    const cookieValue = cookies[CURRENT_STORE_COOKIE_NAME] || undefined
    return CookieStoreResolver.getStoreFromCookie(cookieValue)
  }

  public static async resolveStoreFromCookies({
    cookies
  }: Cookies): Promise<Store | undefined> {
    const storeCookie = cookies?.store || cookies?.country
    if (storeCookie) {
      return this.getStoreFromCookie(storeCookie)
    } else {
      return Promise.resolve(undefined)
    }
  }

  private static async getStoreFromCookie(
    cookieValue: string | undefined
  ): Promise<Store | undefined> {
    if (cookieValue === undefined) {
      return Promise.resolve(undefined)
    } else {
      const country = stringToCountry(cookieValue)
      if (country) return Promise.resolve(countryToStore(country))
      else return Promise.resolve(undefined)
    }
  }
}
