import { Country } from "@sixty-six-north/i18n"
import _isEqual from "lodash/isEqual"
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next"
import { setCookie } from "nookies"
import { lastValueFrom } from "rxjs"
import { memoizedFn, memoTimeout } from "../Cache"
import { Store, Stores } from "../cart/Stores"
import { categoryDal } from "../category/CategoryDal"
import { getActiveFeatureFlagsFromContext } from "../FeatureFlags"
import { CURRENT_STORE_COOKIE_NAME } from "../i18n/Cookies"
import { getRegionForCountry } from "../i18n/Region"
import { GlobalMessageBarItem } from "../layout/MessageBarHooks"
import { prismicDal } from "../prismic/PrismicDal"
import {
  PrismicCheckoutConfiguration,
  PrismicDocument,
  PrismicLinkedDocument,
  SimplePrismicDocument
} from "../prismic/PrismicModels"
import { DomainCategory } from "../product/models/DomainCategory"
import { productDal } from "../product/ProductDal"
import { PrismicContentResolver } from "../routing/PrismicContentResolver"
import {
  cachingExchangeRatesResolver,
  Rates
} from "../tagmanager/ExchangeRatesContext"
import { CookieStoreResolver } from "./CookieStoreResolver"
import { GeographicStoreResolver } from "./GeographicStoreResolver"
import { GlobalProps } from "./GlobalProps"
import { StoreResolver } from "./StoreResolver"

const formatNavigationDocument = (doc: PrismicDocument) => ({
  data: {
    ...doc.data,
    additional_product_filters: null,
    allowed_product_filters:
      doc.data?.additional_product_filters?.map(pf => pf.name).filter(i => i) ||
      []
  },
  id: doc.id,
  uid: doc.uid,
  alternate_languages: doc.alternate_languages || []
})

const formatMessageBarMessage = (message, idx) => {
  const {
    title,
    link: { id = null, url = null },
    link_text
  } = message
  return {
    id: idx,
    title: title[0] ? title[0].text : null,
    link: {
      id,
      label: link_text[0] ? link_text[0].text : null,
      href: url
    }
  }
}

const formatNavigationTrayDocument = (doc: PrismicDocument) => ({
  data: doc.data,
  id: doc.id,
  uid: doc.uid,
  alternate_languages: doc.alternate_languages || []
})
const formatNavigationLinkedDocument = (doc: PrismicLinkedDocument) => ({
  data: {
    path: doc.data?.path || ""
  },
  alternate_languages: doc.alternate_languages,
  id: doc.id,
  uid: doc.uid
})

enum GlobalPropsErrors {
  RegionDoesNotExist = "RegionDoesNotExist"
}

export class GlobalPropsResolver {
  private prismicResolver = new PrismicContentResolver()

  public async fromServerSideContext(
    context: GetServerSidePropsContext,
    store: Store
  ): Promise<GlobalProps> {
    const commerceToolsStore = productDal.fetchStore(store)
    const navigationProps = this.createNavigationProps(store, context).catch(
      () => ({})
    )
    const checkoutProps = this.createCheckoutConfigurationProps(store).catch(
      () => ({})
    )
    const categoryProps = this.createCategoryProps(store).catch(() => ({
      categories: []
    }))

    const exchangeRateProps = this.createExchangeRateProps()

    const storeFromGeography =
      await GeographicStoreResolver.create().resolveStore(context)

    const cookieStore =
      CookieStoreResolver.resolveStoreFromUserPreference(context)

    const userPreferredStore = cookieStore.then(it => {
      if (it === undefined) {
        return null
      } else {
        return {
          country: it.country,
          userSelected: true
        }
      }
    })

    const regionProps = {
      region: getRegionForCountry(store.country),
      clientCountry: await userPreferredStore
    }

    const { features } = getActiveFeatureFlagsFromContext(context)
    const THIRTY_DAYS = 30 * 24 * 60 * 60
    setCookie(context, CURRENT_STORE_COOKIE_NAME, store.country, {
      maxAge: THIRTY_DAYS,
      path: "/"
    })

    return {
      store: {
        currentStore: store,
        storeFromCookie: await cookieStore.then(it =>
          it === undefined ? null : it
        ),
        storeFromGeography: await storeFromGeography,
        commerceToolsStore: await commerceToolsStore
      },
      features,
      ...regionProps,
      ...(await categoryProps),
      ...(await checkoutProps),
      ...(await navigationProps),
      ...(await exchangeRateProps)
    }
  }

  public async createCategoryProps(
    store: Store
  ): Promise<{ categories: DomainCategory[] }> {
    return memoizedFn(
      `category-props-${store.country}`,
      memoTimeout.minutes(1),
      () => this.uncachedCategoryProps(store)
    )
  }

  public async createExchangeRateProps(): Promise<{ exchangeRates: Rates }> {
    const emptyRates: Rates = {}
    return lastValueFrom(cachingExchangeRatesResolver.latest(), {
      defaultValue: { rates: emptyRates }
    }).then(latest => {
      return { exchangeRates: latest.rates }
    })
  }

  public async createNavigationProps(
    store: Store,
    context: GetServerSidePropsContext
  ): Promise<{
    messages?: GlobalMessageBarItem[]
    navigation?: SimplePrismicDocument
    navigationLinkedDocuments?: SimplePrismicDocument[]
    navigationTrays?: SimplePrismicDocument[]
  }> {
    return memoizedFn(
      `navigation-props-${store.country}`,
      memoTimeout.minutes(1),
      () => this.uncachedNavigationProps(store, context)
    )
  }

  private async uncachedCategoryProps(
    store: Store
  ): Promise<{ categories: DomainCategory[] }> {
    const excludeKidsCategoriesWhenNotInIceland = (
      it: DomainCategory
    ): boolean => {
      const categoryExclusions = "kids"
      return (
        _isEqual(store, Stores.IS) || !it?.key?.startsWith(categoryExclusions)
      )
    }

    return categoryDal
      .all()
      .then(categories =>
        categories.filter(excludeKidsCategoriesWhenNotInIceland)
      )
      .then(categories => ({ categories }))
  }

  public async createCheckoutConfigurationProps(store: Store) {
    return memoizedFn(
      `checkout-configuration-props-${store.country}`,
      memoTimeout.minutes(1),
      () => this.uncachedCheckoutConfigProps(store)
    )
  }

  private async uncachedCheckoutConfigProps(store: Store): Promise<{
    checkoutConfiguration?: PrismicCheckoutConfiguration
  }> {
    const formatCheckoutDocument = (doc: PrismicDocument | undefined) => {
      const { disable_checkout = false, checkout_messages } = doc?.data || {}
      const { title, description } =
        checkout_messages?.find(m => m.language === store.language) || {}
      return {
        disabled: Boolean(disable_checkout),
        title: title?.[0] ? title[0].text : "",
        description: description?.[0] ? description[0].text : ""
      }
    }

    const checkoutDocument = prismicDal.checkoutConfig().then(it => it[0])

    return {
      checkoutConfiguration: await checkoutDocument.then(doc =>
        formatCheckoutDocument(doc)
      )
    }
  }

  private async uncachedNavigationProps(
    store: Store,
    context: GetServerSidePropsContext
  ): Promise<{
    messages?: GlobalMessageBarItem[]
    navigation?: SimplePrismicDocument
    navigationLinkedDocuments?: SimplePrismicDocument[]
    navigationTrays?: SimplePrismicDocument[]
  }> {
    const dal = prismicDal
    const country = store.country as Country
    const navigationDocument = dal.navigation(country).then(it => it[0])
    const navigationTrayDocuments = dal.navigationTrays(country)
    const messageBarDocument = dal.messages(country).then(it => it[0])

    const linkedDocuments = Promise.all([
      navigationTrayDocuments,
      messageBarDocument
    ])
      .then(docs => navigationDocument.then(doc => [doc, ...docs.flat()]))
      .then(docs => this.prismicResolver.resolveLinked(docs))

    const messages = await messageBarDocument.then(
      it =>
        it.data?.messages
          ?.map(formatMessageBarMessage)
          .filter(m => m.title || m.link.id || m.link.href) || []
    )

    const navigation = await navigationDocument.then(formatNavigationDocument)
    const navigationTrays = await navigationTrayDocuments.then(docs =>
      docs.map(formatNavigationTrayDocument)
    )
    const navigationLinkedDocuments = await linkedDocuments.then(docs =>
      docs.map(formatNavigationLinkedDocument)
    )

    return {
      navigation,
      navigationTrays,
      navigationLinkedDocuments,
      messages
    }
  }
}

export const GlobalPropsService = new GlobalPropsResolver()

export const getGlobalServerSideProps = async (
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<GlobalProps>> => {
  try {
    const store = await StoreResolver.fromServerSideContext(context)
    const props = await GlobalPropsService.fromServerSideContext(context, store)
    return { props }
  } catch (error) {
    const store = Stores.US
    const categoryProps = GlobalPropsService.createCategoryProps(store)
    const navigationProps = GlobalPropsService.createNavigationProps(
      store,
      context
    )
    const checkoutProps =
      GlobalPropsService.createCheckoutConfigurationProps(store)
    const exchangeRatesProps = GlobalPropsService.createExchangeRateProps()
    const { features } = getActiveFeatureFlagsFromContext(context)

    if (error === GlobalPropsErrors.RegionDoesNotExist) {
      const props: GlobalProps = {
        error: { statusCode: 404 },
        store: { currentStore: store },
        features,
        region: getRegionForCountry(store.country),
        ...(await categoryProps),
        ...(await checkoutProps),
        ...(await navigationProps),
        ...(await exchangeRatesProps)
      }
      return { props }
    } else {
      throw error
    }
  }
}

export default GlobalPropsService
