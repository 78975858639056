import { Country } from "@sixty-six-north/i18n"
import {
  Client,
  PrismicDocument as PrismicV7Document,
  filter
} from "@prismicio/client"
import { BuildQueryURLArgs } from "@prismicio/client/src/buildQueryURL"
import { DEFAULT_REGION } from "i18n/Region"
import { memoizedFn, memoTimeout } from "../Cache"
import { distinctBy } from "../product/ProductDal"
import { PrismicV7Client } from "./PrismicClient"
import {
  getDefaultLocaleForCountry,
  getLocaleForCountry
} from "./PrismicLocale"
import { PrismicDocument, SimplePrismicDocument } from "./PrismicModels"

const toInternalPrismicDocument = (document: PrismicV7Document) =>
  document as PrismicDocument
const convertToInternalPrismicDocuments = (results: PrismicV7Document[]) =>
  results.map(toInternalPrismicDocument)
export class PrismicDal {
  private clientV7: Client

  constructor(client: Client) {
    this.clientV7 = PrismicV7Client
  }

  public allEntries(): Promise<PrismicDocument[]> {
    return this.clientV7
      .getAllByType("entry")
      .then(convertToInternalPrismicDocuments)
  }

  public allProductArticles(ref?: string): Promise<PrismicDocument[]> {
    return memoizedFn(`all-product-articles`, memoTimeout.minutes(1), () =>
      this.clientV7
        .getAllByType("product_articles", {
          lang: "*",
          ...(ref ? { ref } : null)
        })
        .then(convertToInternalPrismicDocuments)
    )
  }

  public queryDocumentsByPath(
    path: string,
    lang = "*"
  ): Promise<PrismicDocument[]> {
    const results = this.clientV7
      .getAllByType("entry", {
        filters: [filter.at("my.entry.path", path)],
        lang
      })
      .then(convertToInternalPrismicDocuments)

    return results.then(documents => distinctBy(documents, it => it.id))
  }

  public queryDocumentsByTags(
    tags: string[],
    locale: string
  ): Promise<PrismicDocument[]> {
    const results = this.clientV7
      .getAllBySomeTags(tags, { lang: locale })
      .then(convertToInternalPrismicDocuments)

    return results.then(documents => distinctBy(documents, it => it.id))
  }

  public async queryDocuments(
    country: Country,
    path: string
  ): Promise<PrismicDocument[]> {
    const specificQuery = this.queryDocumentsByPath(
      path,
      getLocaleForCountry(country)
    )
    const fallbackQuery = this.queryDocumentsByPath(
      path,
      getDefaultLocaleForCountry(country)
    )
    const specificResults = await specificQuery
    const fallbackResults = await fallbackQuery
    return distinctBy([...specificResults, ...fallbackResults], it => it.id)
  }

  public forIds(ids: string[], ref?: string): Promise<PrismicDocument[]> {
    return this.clientV7
      .getAllByIDs(ids, {
        lang: "*",
        ...(ref ? { ref } : null)
      })
      .then(convertToInternalPrismicDocuments)
  }

  public shelvesFor(country: Country): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale("listing_configuration", country)
  }

  public tooltipsFor(
    country: Country,
    ref?: string
  ): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale(
      "tooltip",
      country,
      ref ? { ref } : undefined
    )
  }

  public queryDocumentsByProductCode(productCode: string, ref?: string) {
    const formatDocument = doc => ({
      id: doc.id,
      data: doc.data,
      alternate_languages: doc.alternate_languages
    })
    const fetchDocuments = async () => {
      const results = await this.clientV7.getAllByType("product_content", {
        filters: [
          filter.at("my.product_content.product_key_text", productCode)
        ],
        ...(ref ? { ref } : null)
      })
      const data: PrismicDocument[] = convertToInternalPrismicDocuments(results)
      return data && data.length ? formatDocument(data[0]) : null
    }

    return memoizedFn(
      `documents-by-product-code-${productCode}`,
      memoTimeout.minutes(1),
      () => fetchDocuments()
    )
  }

  public queryDocumentsByProductPronunciation(
    productName: string,
    ref?: string
  ): Promise<SimplePrismicDocument | null> {
    const formatDocument = doc => ({
      id: doc.id,
      data: doc.data,
      alternate_languages: doc.alternate_languages
    })

    const fetchDocuments = async () => {
      const results = await this.clientV7.getAllByType(
        "product_pronunciation",
        {
          filters: [
            filter.at("my.product_pronunciation.product_name", productName)
          ],
          ...(ref ? { ref } : null)
        }
      )
      const data: PrismicDocument[] = convertToInternalPrismicDocuments(results)
      return data && data.length ? formatDocument(data[0]) : null
    }

    return memoizedFn(
      `documents-by-product-name-${productName}`,
      memoTimeout.minutes(1),
      () => fetchDocuments()
    )
  }

  public navigation(country: Country): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale("new_navigation", country)
  }

  public messages(country: Country): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale("message_bar", country)
  }

  public checkoutConfig(): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale(
      "checkout_configuration",
      DEFAULT_REGION.country
    )
  }

  public navigationTrays(country: Country): Promise<PrismicDocument[]> {
    return this.queryWithFallbackLocale("navigation_tray", country, {
      pageSize: 100
    })
  }

  private async queryWithFallbackLocale(
    documentType: string,
    country: Country,
    options: Partial<Omit<BuildQueryURLArgs, "page">> = {}
  ): Promise<PrismicDocument[]> {
    const specificLocale = {
      lang: getLocaleForCountry(country),
      ...options
    }
    const fallbackLocale = {
      lang: getDefaultLocaleForCountry(country),
      ...options
    }
    const specificQuery = this.clientV7
      .getAllByType(documentType, specificLocale)
      .then(convertToInternalPrismicDocuments)
    const fallbackQuery = this.clientV7
      .getAllByType(documentType, fallbackLocale)
      .then(convertToInternalPrismicDocuments)

    const specificDocuments = await specificQuery
    const fallbackDocuments = await fallbackQuery
    const document: PrismicDocument[] = [
      ...specificDocuments,
      ...fallbackDocuments
    ]
    return distinctBy(document, it => it.id)
  }
}

export const prismicDal = new PrismicDal(PrismicV7Client)

const getNextPages = (result: {
  page: number
  total_pages: number
}): number[] => {
  return result.page === 1
    ? Array(result.total_pages)
        .fill(0)
        .map((it, page) => page + 1)
        .slice(1)
    : []
}
