import { Country } from "@sixty-six-north/i18n"
import { GetServerSidePropsContext } from "next"
import { countryToStore } from "../cart/I18NStores"
import { Store } from "../cart/Stores"
import {
  CountryResolverErrors,
  resolveCountryFromIpAddress
} from "../i18n/CountryResolver"
import { headerXClientCountryValueToCountry } from "../i18n/HeadeXClientCountryValueToCountry"
import { DEFAULT_REGION } from "../i18n/Region"
import { ipAddress } from "./ipAddress"
import { xClientCountryHeaderValue } from "./XClientCountryHeaderValue"

export class GeographicStoreResolver {
  public static create(): GeographicStoreResolver {
    return new GeographicStoreResolver(resolveCountryFromIpAddress)
  }

  public static async resolveStoreFromServerSideContext(
    context: GetServerSidePropsContext
  ): Promise<Store> {
    return GeographicStoreResolver.create().resolveStore(context)
  }

  public readonly fetchCountry: (ip: string) => Promise<Country>
  constructor(fetchCountry: (ip: string) => Promise<Country>) {
    this.fetchCountry = fetchCountry
  }

  public async resolveStore(
    context: GetServerSidePropsContext
  ): Promise<Store> {
    const clientCountry = xClientCountryHeaderValue(context)
    const clientIP = ipAddress(context.req)

    if (clientCountry) {
      return this.getStoreFromClientCountry(clientCountry)
    } else if (clientIP) {
      return this.getStoreFromIpAddress(clientIP)
    }

    return Promise.resolve(countryToStore(DEFAULT_REGION.country))
  }

  public async getStoreFromIpAddress(ip: string): Promise<Store> {
    const country = await this.fetchCountry(ip)
    if (country) return Promise.resolve(countryToStore(country))
    else return Promise.reject(CountryResolverErrors.CountryDoesNotExist)
  }

  public getStoreFromClientCountry(clientCountry: string): Promise<Store> {
    const country = headerXClientCountryValueToCountry(clientCountry)
    if (country) return Promise.resolve(countryToStore(country))
    else return Promise.reject(CountryResolverErrors.CountryDoesNotExist)
  }
}
