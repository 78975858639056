import { Country, stringToCountry } from "@sixty-six-north/i18n"
import _keys from "lodash/keys"
import _includes from "lodash/includes"
import { REGION_COUNTRIES } from "./Region"

export const headerXClientCountryValueToCountry = (value: string): Country => {
  const mapEUCountriesToEU = (aCountryValue: string): string => {
    const euCountryKeys: string[] = _keys(REGION_COUNTRIES.EU)
    if (_includes(euCountryKeys, value.toUpperCase())) {
      return "eu"
    } else {
      return aCountryValue
    }
  }
  const supportCountry = stringToCountry(mapEUCountriesToEU(value))

  return supportCountry ? supportCountry : Country.us
}
