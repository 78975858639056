import { ProductProjection } from "@commercetools/platform-sdk"
import { Option } from "funfix-core"
import { countryToPath } from "../i18n/Country"
import { Region } from "../i18n/Region"
import { GlobalProps } from "../next/GlobalProps"
import { prismicDal } from "../prismic/PrismicDal"
import { PrismicIdAggregator } from "../prismic/PrismicIdAggregator"
import {
  getDefaultLocaleForCountry,
  getLocaleForCountry
} from "../prismic/PrismicLocale"
import {
  PrismicDocument,
  PrismicLinkedDocument
} from "../prismic/PrismicModels"
import {
  PrismicProductAttributeAggregator,
  PrismicProductCodeAggregator
} from "../prismic/PrismicProductAggregators"
import {
  byProductAttributes,
  byProductCodes,
  distinctBy,
  productDal
} from "../product/ProductDal"
import { PrismicPageParams, PrismicPageProps } from "./PrismicPageParams"

export class PrismicContentResolver {
  private dal = prismicDal

  public resolve = async (
    params: PrismicPageParams,
    header: GlobalProps
  ): Promise<PrismicPageProps | undefined> => {
    return this.actualResolve(params, header)
  }

  public actualResolve = async (
    params: PrismicPageParams,
    header: GlobalProps
  ): Promise<PrismicPageProps | undefined> => {
    const documents = this.dal.queryDocumentsByPath(params.prismicPath)
    const alternativeLanguageDocuments = documents.then(docs => {
      const ids = docs.flatMap(it => it.alternate_languages).map(it => it.id)
      if (ids.length === 0) {
        return Promise.resolve([])
      } else {
        return this.dal.forIds(ids)
      }
    })

    const linkedDocuments = documents.then(results =>
      this.resolveLinked(results)
    )

    const relatedContentBlocks = documents.then(results =>
      this.resolveRelatedContentBlocks(results)
    )

    try {
      const resolvedDocuments = await documents
      const resolvedAlternativeLanguageDocuments =
        await alternativeLanguageDocuments
      const resolvedLinkedDocuments = await linkedDocuments
      const resolvedRelatedContentBlocks = await relatedContentBlocks

      const documentByPath = this.pickDocument(
        header.region,
        params.prismicPath,
        [...resolvedDocuments, ...resolvedAlternativeLanguageDocuments]
      )

      const documentByUid = this.pickDocument(
        header.region,
        params.prismicUid,
        [...resolvedDocuments, ...resolvedAlternativeLanguageDocuments]
      )

      const actualDocument = documentByPath || documentByUid

      const redirect =
        actualDocument?.data?.path === params.prismicPath
          ? null
          : {
              url: `/${countryToPath(header.region.country)}${
                actualDocument?.data?.path
              }`
            }

      if (
        redirect !== null &&
        resolvedDocuments.length > 0 &&
        actualDocument !== undefined
      ) {
        return {
          prismicPage: {
            params,
            document: actualDocument,
            linkedDocuments: resolvedLinkedDocuments,
            relatedContentBlocks: resolvedRelatedContentBlocks,
            redirect,
            products: []
          }
        }
      } else if (resolvedDocuments.length > 0 && actualDocument !== undefined) {
        return {
          prismicPage: {
            params,
            document: actualDocument,
            linkedDocuments: resolvedLinkedDocuments,
            relatedContentBlocks: resolvedRelatedContentBlocks,
            products: []
          }
        }
      } else {
        return undefined
      }
    } catch (err) {
      throw err
    }
  }

  public pickDocument(
    region: Region,
    pathOrUid: string,
    documents: PrismicDocument[]
  ): PrismicDocument | undefined {
    const byLanguage = documents.find(
      it => it.lang === getLocaleForCountry(region.country)
    )

    const byDefaultLanguage = documents.find(
      it => it.lang === getDefaultLocaleForCountry(region.country)
    )

    const byRegion = documents.find(it => it.data?.region === region.country)

    const byRegionAndPath = documents
      .filter(it => it.data?.region === region.country)
      .find(it => it.data?.path === pathOrUid)

    const byLanguageAndPath = documents
      .filter(it => it.lang === getLocaleForCountry(region.country))
      .find(it => it.data?.path === pathOrUid)

    const byPath = documents.find(it => it.data?.path === pathOrUid)
    const byUid = documents.find(it => it.uid === pathOrUid)

    return (
      byLanguage ||
      byRegion ||
      byRegionAndPath ||
      byLanguageAndPath ||
      byDefaultLanguage ||
      byPath ||
      byUid
    )
  }

  public async resolveRelatedContentBlocks(
    results: PrismicDocument[]
  ): Promise<PrismicLinkedDocument[]> {
    const aggregator = new PrismicIdAggregator()
    const ids = aggregator.aggregateRelatedContentBlocks(results)
    if (!ids.length) {
      return []
    }

    const blockDocuments = await this.dal.forIds(ids)
    const linkIds: string[] = blockDocuments
      .flatMap(d => (d.data?.links || [])?.map(l => l.link.id))
      .filter(i => i)
    const allLinks = linkIds.length ? await this.dal.forIds(linkIds) : []
    const documentIds = results.map(r => r.id)

    return blockDocuments.map((it: PrismicDocument) => {
      const documentLinks = it.data?.links || []
      const document: PrismicLinkedDocument = {
        id: it.id,
        uid: it.uid,
        alternate_languages: it.alternate_languages,
        lang: it.lang,
        data: {
          title: it.data?.title || [],
          description: it.data?.description || [],
          links: allLinks
            .filter(
              l =>
                documentLinks.find(dl => dl.link.id === l.id) &&
                documentIds.indexOf(l.id) === -1
            )
            .map(i => ({
              link: i
            }))
        }
      }
      return document
    })
  }

  public resolveLinked(
    results: PrismicDocument[]
  ): Promise<PrismicLinkedDocument[]> {
    const aggregator = new PrismicIdAggregator()
    const ids = aggregator.aggregateMany(results)

    return this.dal.forIds(ids).then(results => {
      return results.map((it: PrismicDocument) => {
        const document: PrismicLinkedDocument = {
          id: it.id,
          uid: it.uid,
          alternate_languages: it.alternate_languages,
          lang: it.lang,
          data: {
            path: it.data?.path,
            display_url: it.data?.display_url
          }
        }

        const pathData = Option.of(it.data?.path)
          .map(path => {
            return {
              path
            }
          })
          .getOrElse({ path: null })

        const displayUrlData = Option.of(it.data?.display_url)
          .map(displayUrl => {
            return {
              display_url: displayUrl
            }
          })
          .getOrElse({ display_url: null })

        const metaData = {
          thumbnail: it.data?.thumbnail || null,
          meta_title: it.data?.meta_title || null,
          meta_description: it.data?.meta_description || null,
          meta_list_description: it.data?.meta_list_description || null,
          link_label: it.data?.link_label || null
        }

        document["data"] = {
          ...pathData,
          ...displayUrlData,
          ...metaData
        }

        return document
      })
    })
  }

  public async resolveProducts(
    results: PrismicDocument[]
  ): Promise<ProductProjection[]> {
    try {
      const productsByCode = this.resolveProductsByCode(results)
      const productsByAttribute = this.resolveProductsByAttribute(results)

      const resolvedByCode = await productsByCode
      const resolvedByAttr = await productsByAttribute

      const allProducts: ProductProjection[] = [
        ...resolvedByCode,
        ...resolvedByAttr
      ]

      return distinctBy(allProducts, it => it.key)
    } catch (e) {
      return []
    }
  }

  private resolveProductsByCode(
    results: PrismicDocument[]
  ): Promise<ProductProjection[]> {
    const aggregator = new PrismicProductCodeAggregator()
    const productCodes = aggregator.aggregateMany(results)
    return productDal.query(byProductCodes(productCodes))
  }

  private resolveProductsByAttribute(
    results: PrismicDocument[]
  ): Promise<ProductProjection[]> {
    const aggregator = new PrismicProductAttributeAggregator()
    const productAttributes = aggregator.aggregateMany(results)
    return productDal.query(byProductAttributes(productAttributes))
  }
}
