import { GetServerSidePropsContext } from "next"
import { DEFAULT_STORE } from "../cart/I18NStores"
import { Store, Stores } from "../cart/Stores"
import { createLogger } from "../utils/createLogger"
import { CookieStoreResolver } from "./CookieStoreResolver"
import { GeographicStoreResolver } from "./GeographicStoreResolver"

const logger = createLogger("StoreResolver")

export class StoreResolver {
  public static async forCheckoutPages(
    context: GetServerSidePropsContext
  ): Promise<Store> {
    const cookieStoredCurrentStore =
      await CookieStoreResolver.resolveStoreFromCurrentStoreCookie(context)

    const userPreferredStore =
      await CookieStoreResolver.resolveStoreFromUserPreference(context)

    const storeFromGeography =
      await GeographicStoreResolver.resolveStoreFromServerSideContext(context)

    return (
      cookieStoredCurrentStore ||
      userPreferredStore ||
      storeFromGeography ||
      DEFAULT_STORE
    )
  }

  public static async fromServerSideContext(
    context: GetServerSidePropsContext
  ): Promise<Store> {
    return new StoreResolver(GeographicStoreResolver.create()).resolveStore(
      context
    )
  }

  public readonly geoStoreResolver: GeographicStoreResolver
  constructor(geoStoreResolver: GeographicStoreResolver) {
    this.geoStoreResolver = geoStoreResolver
  }

  private async resolveStore(
    context: GetServerSidePropsContext
  ): Promise<Store> {
    const storeFromCookies =
      await CookieStoreResolver.resolveStoreFromUserPreference(context)
    const storeFromGeography = await this.geoStoreResolver
      .resolveStore(context)
      .catch(error => {
        logger.error("Failed resolving geographic location", error)
        return undefined
      })

    if (storeFromCookies) {
      return storeFromCookies
    } else if (storeFromGeography) {
      return storeFromGeography
    } else {
      return Stores.US
    }
  }
}
