//jsxhook
import { Country } from "@sixty-six-north/i18n"
import { Column, H6, LinkVariants } from "@sixty-six-north/ui-system"
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextPage
} from "next"
import Head from "next/head"
import { ParsedUrlQuery } from "querystring"
import React from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { Store } from "../cart/Stores"
import { useCategory } from "../category/CategoryService"
import { CurrentFilters } from "../category/components/CurrentFilters"
import { ProductNavBar } from "../category/components/ProductNavBar"
import { ProductShelf } from "../category/components/ProductShelf"
import { ProductFacets } from "../category/ProductFacetFactory"
import { ProductListingProvider } from "../category/ProductListing"
import Layout from "../components/Layout"
import Link from "../components/Link"
import { createSearchLink, useCategoryLinkBuilder } from "../components/Links"
import { WrongTurn } from "../components/WrongTurn"
import { FilterProvider } from "../filters/FilterProvider"
import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { GlobalProps } from "../next/GlobalProps"
import GlobalPropsService, {
  GlobalPropsResolver
} from "../next/GlobalPropsService"
import { pageToOffset, sortFromQuery } from "../next/Pagination"
import { StoreResolver } from "../next/StoreResolver"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainCategory } from "../product/models/DomainCategory"
import { productDal } from "../product/ProductDal"
import { OrderBy } from "../product/ProductDalTypes"
import { Shelf, shelvesDal } from "../shelves/ShelvesDal"

export interface SearchProps {
  shelves: Shelf[]
  products: CoreProductInformation[]
  facets: ProductFacets
}

export const suggestedSearches = [
  {
    name: "Gore-Tex in Blue",
    translationKey: "goreTextInBlueSearch"
  },
  {
    name: "Shell Jackets for running",
    translationKey: "shellJacketsForRunningSearch"
  },
  {
    name: "Down Jackets for Snowy conditions",
    translationKey: "downJacketsForSnowyConditionsSearch"
  }
]

export const SearchPage: NextPage<SearchProps & GlobalProps> = ({
  products,
  facets,
  shelves
}) => {
  const { t } = useTranslation("search")

  return (
    <FilterProvider>
      <ProductListingProvider products={products} facets={facets}>
        <Layout>
          <Head>
            <title>{[t("search"), "|", t("66north")].join(" ")} </title>
          </Head>

          {products.length > 0 && (
            <>
              <ProductNavBar />
              <CurrentFilters />
              <ProductShelf
                splitProductsByRootCategory={true}
                shelves={shelves}
              />
            </>
          )}
          {products.length === 0 && <NoResults />}
        </Layout>
      </ProductListingProvider>
    </FilterProvider>
  )
}

export const NoResults: React.FC = ({}) => {
  const { t } = useTranslation("search")
  const { country } = useStoreContext()

  return (
    <WrongTurn
      title={t("search:noResultsMatched", "No results matched your search")}
      message={
        <React.Fragment>
          {" "}
          {t(
            "layout:egKemAlvegAfFjollum",
            `Ég kem alveg af fjöllum — "I come completely from mountains"`
          )}{" "}
        </React.Fragment>
      }
    >
      <H6 sx={{ mb: 6, color: "black" }}>
        {t("products:whyNotTry", "Why not try")}
      </H6>
      <Flex sx={{ flexDirection: ["column", "column", "row"], mb: 40 }}>
        {suggestedSearches.map((suggested, idx) => {
          return (
            <Link
              {...createSearchLink(
                t(suggested.translationKey),
                country,
                OrderBy.priceDesc
              )}
              sx={{
                mr: 26,
                mb: 8,
                variant: `links.${LinkVariants.standardNoUnderline}`
              }}
              key={idx}
            >
              <H6>{t(suggested.translationKey)}</H6>
            </Link>
          )
        })}
      </Flex>
    </WrongTurn>
  )
}

export const RootCategory: React.FC<{ category: DomainCategory }> = ({
  category
}) => {
  const language = usePreferredLanguage()
  const service = useCategory(category)
  const categoryLinkBuilder = useCategoryLinkBuilder()
  return (
    <Column sx={{ mr: 62, mb: 32 }}>
      <H6 sx={{ mb: 8 }}>{category.name[language]}</H6>
      {service.subCategoriesSortedByOrderHint().map(cat => {
        const categoryLink = categoryLinkBuilder.categoryLink(cat)
        return (
          <Link
            {...categoryLink}
            key={cat.key}
            sx={{ mb: 8, variant: `links.${LinkVariants.standardNoUnderline}` }}
          >
            <H6>{cat.name[language]}</H6>
          </Link>
        )
      })}
    </Column>
  )
}

export const getServerSideSearchPropsForStore =
  (store: Store): GetServerSideProps<SearchProps & GlobalProps> =>
  async (
    context: GetServerSidePropsContext<ParsedUrlQuery>
  ): Promise<GetServerSidePropsResult<SearchProps & GlobalProps>> => {
    const globalProps: GlobalProps =
      await new GlobalPropsResolver().fromServerSideContext(context, store)

    const offset = pageToOffset(context.query.page)
    const orderBy: OrderBy = sortFromQuery(context.query.sortBy)

    const searchQuery = getSearchQuery(context.query.query || "")
    const { products, facets } = await productDal.search(
      searchQuery,
      store,
      orderBy,
      offset
    )
    const shelves = await shelvesDal.shelvesFor(store.country as Country)

    return {
      props: {
        searchQuery,
        products,
        facets,
        shelves,
        ...globalProps
      }
    }
  }

export const getSearchProps = async (
  context: GetServerSidePropsContext
): Promise<SearchProps & GlobalProps> => {
  const store = await StoreResolver.fromServerSideContext(context)
  const globalProps = await GlobalPropsService.fromServerSideContext(
    context,
    store
  )

  const offset = pageToOffset(context.query.page)
  const orderBy: OrderBy = sortFromQuery(context.query.sortBy)

  const searchQuery = getSearchQuery(context.query.query || "")
  const { products, facets } = await productDal.search(
    searchQuery,
    store,
    orderBy,
    offset
  )
  const shelves = await shelvesDal.shelvesFor(store.country as Country)

  return {
    searchQuery,
    products,
    facets,
    shelves,
    ...globalProps
  }
}

const getSearchQuery = (query: string | string[]) => {
  return query as string
}
