import _flatten from "lodash/fp/flatten"
import _first from "lodash/fp/first"
import _flow from "lodash/fp/flow"

export const xClientCountryHeaderValue = (context: {
  req: { headers: { [header: string]: string | string[] | undefined } }
}): string | undefined => {
  const value = context.req.headers["x-client-country"]
  if (value) {
    return _flow(_flatten, _first)([value])
  } else {
    return undefined
  }
}
