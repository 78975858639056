import { AppConfig } from "../Config"

export interface PrismicConfigI {
  repository: string
  accessToken: string
  clientId: string
  clientSecret: string
  apiEndpoint: string
}

export const PrismicConfig: PrismicConfigI = {
  repository: AppConfig.prismic.repository,
  accessToken: AppConfig.prismic.accessToken,
  clientId: AppConfig.prismic.clientId,
  clientSecret: AppConfig.prismic.clientSecret,
  apiEndpoint: `https://${AppConfig.prismic.repository}.prismic.io/api/v2`
}
