//jsxhook

import {
  Column,
  H2,
  H5,
  H6,
  LinkVariants,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { useRootCategories } from "../category/CategoryHooks"
import { byOrderHint } from "../category/CategoryService"
import { usePreferredLanguage } from "../i18n/StoreHooks"
import { RootCategory } from "../search/SearchPage"
import Link from "./Link"
import { useCategoryLink, useCategoryLinkBuilder } from "./Links"

export const WrongTurn: React.FC<{
  title: React.ReactNode
  message: React.ReactNode
  children?: React.ReactNode[]
}> = props => {
  const { title, message, children } = props
  const language = usePreferredLanguage()
  const rootCategories = useRootCategories().sort(byOrderHint)
  const linkBuilder = useCategoryLinkBuilder()

  const { t } = useTranslation("error")

  return (
    <Flex sx={{ px: StandardXAxisPadding }}>
      <Column sx={{ mt: 112, mb: 40, mx: "auto" }}>
        <H2 sx={{ mb: 16 }}>{title}</H2>
        <H5 sx={{ color: "grey.1", mb: 48 }}>{message}</H5>
        {children}
        <H6 sx={{ mb: 6, color: "black" }}>
          {t("error:shopLinks", { defaultValue: "Shop" })}
        </H6>
        <Flex sx={{ flexDirection: ["column", "column", "row"], mb: 40 }}>
          {rootCategories.map((category, idx) => {
            return (
              <Link
                {...linkBuilder.categoryLink(category)}
                sx={{
                  mr: 26,
                  mb: 8,
                  variant: `links.${LinkVariants.standardNoUnderline}`
                }}
                key={idx}
              >
                <H6>{category.name[language]}</H6>
              </Link>
            )
          })}
        </Flex>
        <Flex sx={{ flexDirection: ["column", "column", "row"], mb: 20 }}>
          {rootCategories.map(root => {
            return <RootCategory category={root} key={root.id} />
          })}
        </Flex>
      </Column>
    </Flex>
  )
}
