import { IncomingMessage } from "http"
import _isArray from "lodash/isArray"
import _isString from "lodash/isString"

export const ipFromXForwarded = (
  xForwardedFor: string | string[] | undefined
): string | undefined => {
  if (xForwardedFor && _isArray(xForwardedFor)) {
    return xForwardedFor[0]
  } else if (xForwardedFor && _isString(xForwardedFor)) {
    return xForwardedFor.split(",")[0]
  } else {
    return undefined
  }
}

export const ipAddress = (request: IncomingMessage): string | undefined => {
  const forwardedIpAddress = ipFromXForwarded(
    (request?.headers || {})["x-forwarded-for"]
  )

  const remoteAddress = request?.connection?.remoteAddress

  if (forwardedIpAddress) {
    return forwardedIpAddress
  } else {
    return remoteAddress
  }
}
