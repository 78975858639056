import { OrderBy } from "../product/ProductDalTypes"

export const RESULTS_PER_PAGE = 20

const parseToPageNumber = (page: number | string | string[]): number => {
  if (typeof page === "number") return page
  else if (typeof page === "string") return parseInt(page, 10)
  else return parseInt(page[0], 10)
}

export const pageToOffset = (
  page: number | string | string[] | undefined
): number => {
  if (page === undefined) {
    return 0
  } else {
    const parsedPage = parseToPageNumber(page)

    if (isNaN(parsedPage)) {
      return 0
    } else {
      return Math.max(Math.max(parsedPage, 1) - 1, 0) * RESULTS_PER_PAGE
    }
  }
}

export const hasNextPage = (offset: number, total: number | undefined) => {
  if (total === undefined) {
    return false
  } else {
    return offset - (offset % RESULTS_PER_PAGE) + RESULTS_PER_PAGE < total
  }
}

export const nextPage = (offset: number): number => {
  return (offset - (offset % RESULTS_PER_PAGE)) / RESULTS_PER_PAGE + 2
}

export const sortFromQuery = (sort: string | string[] | undefined): OrderBy => {
  if (sort === undefined) {
    return OrderBy.priceAsc
  } else if (typeof sort === "string") {
    const key = sort as string
    return OrderBy[key]
  } else {
    return sortFromQuery(sort[0])
  }
}
